
const styles = {
    primaryButton: 'bg-primary px-4 py-2 rounded-full h-14 items-center justify-center',
    primaryButtonText: 'text-white font-medium',

    // Secondary Button
    secondaryButton: 'bg-transparent px-4 py-2 border border-stroke dark:border-darkAccent rounded-full h-14 items-center justify-center ',
    secondaryButtonText: 'text-dark dark:text-light font-medium',
    primaryText: "text-primary font-medium",
    // Text Styles
    boldText: 'font-bold',
    smallText: 'text-sm',
    lightText: 'text-light',
    mediumText: 'text-base',
    largeText: 'text-lg',

    // Icons (Assuming you're using a library like react-native-vector-icons)
    icon: 'text-base',

    // Inputs
    input: 'border border-gray-300 rounded-md block px-2 text-base text-dark dark:text-dark w-full h-12 font-KumbhSans mt-1',

    // Search Inputs
    searchInput: 'border border-[#F7F7F7] dark:border-darkAccent rounded-full bg-[#F7F7F7] text-base text-dark dark:text-dark h-12 leading-5 font-KumbhSans',
}

export { styles };