import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import logo from "./logo.png";
import "./App.css";

// Import your components here
import PayRedirect from "./pages/payRedirect";
import Payments from "./pages/payments";

function App() {
  return (
    <div className="App h-full w-full absolute bg-slate-100 overflow-hidden">
      <header className=" relative flex flex-row h-screen">
        <Router>
          <Routes>
            <Route path="/pay-redirect" element={<PayRedirect />} />
            <Route path="/payments/:id_e" element={<Payments />} />
          </Routes>
        </Router>
        <div className="w-full flex flex-row justify-center absolute opacity-30">
          <img src={logo} className=" mt-100 sm:mt-60 md:mt-48" alt="logo" />
        </div>
      </header>
      <div className="w-full flex flex-row justify-center absolute bottom-0 ">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-500 text-left font-KumbhSans p-3 bg-slate-50 w-full text-center"
        >
          Powered by{" "}
          <a
            className="text-primary underline font-semibold"
            href="https://www.jekinraa.com"
          >
            Jekinraa LTD
          </a>
          .
        </label>
      </div>
    </div>
  );
}

export default App;
