import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {styles} from "../components/styles";
import {baseUrl} from "../webbase";
import {getRequest, postRequest} from "../components/useUtilities";

interface Vendor {
  name: string;
  email: string;
  phone: string;
  address: string;
  background_image: string;
  business_name: string;
  logo?: string;
}

interface Product {
  name: string;
  description: string;
  price: string;
  images?: string;
}

interface VendorProductData {
  vendor: Vendor;
  product: Product;
}

const Payments: React.FC = () => {
  let {id_e} = useParams();
  const [isLoading, setIsLoading] = useState(true); // [2]
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orderDetails, setOrderDetails] = useState<VendorProductData | null>(
    null
  );

  const initializePayment = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    postRequest({
      url: baseUrl + "/api/intializepayment", // Replace with your actual API URL
      data: {
        paymentid: id_e,
        name: name,
        email: email,
      },
      onSuccess: (response) => {
        console.log(
          "🚀 ~ file: payments.tsx:25 ~ getPaymentDetails ~ response:",
          response
        );
      },
      onError: (message) => {
        console.error("Error fetching cart:", message);
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    const getPaymentDetails = (id: string) => {
      getRequest({
        url: baseUrl + "/api/vendorproductdetails/" + id_e, // Replace with your actual API URL
        onSuccess: (response) => {
          if (response?.code !== 200 || response?.data[0] === null) {
            throw new Error("Error Getting Order: " + response?.message);
          }

          setOrderDetails(response.data);
          console.log(
            "🚀 ~ file: payments.tsx:25 ~ getPaymentDetails ~ data:",
            response.data
          );
        },
        onError: (message) => {
          console.error("Error Getting Order:", message);
        },
        onFinally: () => {
          setIsLoading(false);
        },
      });
    };
    getPaymentDetails(id_e);
  }, [id_e]);

  return (
    <div className="md:w-1/3 w-full md:m-auto bg-slate-50 px-4 md:px-8 py-10 rounded-md z-10 mx-4 my-auto sm:min-w-96">
      {isLoading && <p>Loading...</p>}
      {orderDetails && (
        <div>
          <div className="flex flex-row space-x-2 items-center mb-4 p-3 border border-gray-200 bg-white rounded-md justify-between">
            <div className="font-semibold text-gray-800">
              Pay {orderDetails?.vendor?.business_name}
            </div>{" "}
            <img
              src={orderDetails?.vendor?.background_image}
              className="h-7 rounded-full w-7"
              alt="logo"
            />
          </div>
          <div className="flex flex-row space-x-3 items-center mb-4 border border-gray-200 bg-white p-2 rounded-md">
            <img
              src={orderDetails?.vendor?.background_image}
              className="h-12 rounded-md w-12"
              alt="logo"
            />
            <div className=" flex-1 ">
              <div className="font-medium text-left text-md text-gray-700">
                {orderDetails?.product.name}
              </div>
              <div className="font-normal flex-1 text-left text-gray-400 text-sm">
                {orderDetails?.product.description}
              </div>
            </div>
            <div className="font-normal text-sm">
              ₦{orderDetails?.product.price}
            </div>
          </div>
        </div>
      )}
      <form onSubmit={initializePayment} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 text-left font-KumbhSans"
          >
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={name}
            placeholder="John Doe"
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            required
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700 text-left font-KumbhSans"
          >
            Email Address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            placeholder="Eg. johndoe@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-primary px-4 py-2 rounded-full h-12 items-center justify-center text-white w-full text-sm"
        >
          Make Payment
        </button>
        <a
          href="mailto:support@example.com?subject=Issue%20Report"
          className="block text-center mt-4 text-gray-500 hover:underline font-KumbhSans text-sm"
        >
          Report Issue?
        </a>
      </form>
    </div>
  );
};

export default Payments;
