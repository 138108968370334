import axios, {AxiosResponse} from "axios";

interface IData {
  [key: string]: string;
}

/**
 * Searches an array of objects for given properties that match the searchTerm.
 * @param dataArray - Array of objects to search through.
 * @param propertyNames - The names of the properties to search for.
 * @param searchTerm - The term to search for.
 * @returns An array of objects that match the search criteria.
 */
export function searchByProperties(
  dataArray: IData[],
  propertyNames: string[],
  searchTerm: string
): IData[] {
  if (!dataArray || !propertyNames || !searchTerm || !propertyNames.length) {
    return [];
  }

  searchTerm = searchTerm.toLowerCase();

  return dataArray.filter((item) => {
    return propertyNames.some((propertyName) => {
      const propertyValue = item[propertyName];
      return propertyValue && propertyValue.toLowerCase().includes(searchTerm);
    });
  });
}

interface APIResponse {
  data: any;
  message: string;
  code: number;
}

interface PostRequestParams {
  url: string;
  data: any;
  onSuccess: (response: APIResponse) => void;
  onError: (message: string) => void;
  onFinally?: () => void;
}

export const postRequest = ({
  url,
  data,
  onSuccess,
  onError,
  onFinally,
}: PostRequestParams) => {
  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data.code === 200) {
        onSuccess(response.data);
      } else {
        onError(response.data.message);
      }
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "An error occurred";
      onError(errorMessage);
    })
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
};

interface GetRequestParams {
  url: string;
  onSuccess: (response: APIResponse) => void;
  onError: (message: string) => void;
  onFinally?: () => void;
}

export const getRequest = ({
  url,
  onSuccess,
  onError,
  onFinally,
}: GetRequestParams) => {
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data.code === 200) {
        onSuccess(response.data);
      } else {
        onError(response.data.message);
      }
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "An error occurred";
      onError(errorMessage);
    })
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
};
